import React from 'react';

const NextBlackIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.3069 10.0339C20.2518 10.6954 20.7243 11.0261 20.8907 11.4381C21.0364 11.7986 21.0364 12.2016 20.8907 12.5621C20.7243 12.9741 20.2518 13.3048 19.3069 13.9662L14.6332 17.2378C13.8185 17.8081 13.4111 18.0933 13.072 18.0785C12.7767 18.0655 12.5022 17.9227 12.3223 17.6882C12.1157 17.4189 12.1157 16.9216 12.1157 15.927V8.07313C12.1157 7.07858 12.1157 6.58131 12.3223 6.31201C12.5022 6.07751 12.7767 5.93461 13.072 5.92169C13.4111 5.90685 13.8185 6.19202 14.6332 6.76236L19.3069 10.0339Z"
      stroke="#252431"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3069 10.0339C11.2518 10.6954 11.7243 11.0261 11.8907 11.4381C12.0364 11.7986 12.0364 12.2016 11.8907 12.5621C11.7243 12.9741 11.2518 13.3048 10.3069 13.9662L5.63323 17.2378C4.81846 17.8081 4.41108 18.0933 4.07197 18.0785C3.77668 18.0655 3.50224 17.9227 3.32231 17.6882C3.11569 17.4189 3.11569 16.9216 3.11569 15.927V8.07313C3.11569 7.07858 3.11569 6.58131 3.32231 6.31201C3.50224 6.07751 3.77668 5.93461 4.07197 5.92169C4.41108 5.90685 4.81846 6.19202 5.63323 6.76236L10.3069 10.0339Z"
      stroke="#252431"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NextBlackIcon;
