export const QuestionMarkIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0833 12.6696C13.3183 12.0018 13.782 11.4387 14.3923 11.08C15.0027 10.7213 15.7203 10.5901 16.418 10.7098C17.1158 10.8295 17.7487 11.1923 18.2046 11.7339C18.6605 12.2755 18.91 12.961 18.909 13.6689C18.909 15.6674 15.9112 16.6667 15.9112 16.6667M15.9479 20.6667H15.9613M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
