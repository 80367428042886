// Built In
import React from 'react';

// External
import Lottie from 'lottie-react';

// Internal
import Loader from '../../assets/lottie/loader.json';

const Spinner = () => {
  return (
    <div className="flex justify-center align-center pos-relative">
      <div className="blur1" />
      <Lottie animationData={Loader} loop autoplay className="loader-lottie" />
    </div>
  );
};

export default Spinner;
