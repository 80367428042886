import React from 'react';

// External
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';
import enUS from 'antd/lib/locale/en_US';

// Internal
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthContextProvider from './context/AuthContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

ConfigProvider.config({
  theme: {
    primaryColor: '#FBC90B',
    errorColor: 'red',
  },
});

message.config({
  duration: 2,
  maxCount: 3,
});

root.render(
  <ConfigProvider
    locale={enUS}
    theme={{
      token: {
        colorPrimary: '#FBC90B',
        colorError: 'red',
      },
    }}
  >
    <HashRouter>
      <AuthContextProvider>
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
      </AuthContextProvider>
    </HashRouter>
  </ConfigProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
