// Built in
import React, { useEffect, useState } from 'react';

// External
import { Button, Divider, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

// Internal
import Fire from '../../assets/icons/Fire';
import Clock from '../../assets/icons/Clock';
import LevelIcon from '../../assets/icons/LevelIcon';
import DumbbellsIcon from '../../assets/icons/DumbbellsIcon';
import styles from './InfoProgram.module.css';
import WorkOutCompletePopup from '../../components/WorkoutCompletePopup/WorkoutCompletePopup';
import { GET_BY_ID_WORKOUT } from '../../services/workout/Workout';
import { Equipment, TrainingZone } from '../info/types';
import MediaPopupProgram from './MediaPopup';
import RestPopup from './RestPopup';
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon';
import defaultImg from '../../assets/imgs/defaultimg.png';
import Spinner from '../../utils/Spinner/Spinner';

const InfoProgram = () => {
  const [restPopupVisible, setRestPopupVisible] = useState(false);
  const [videoPopupVisible, setVideoPopupVisible] = useState(false);
  const [completePopupVisible, setCompletePopupVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [counter, setCounter] = useState(0);
  const [time, setTime] = useState<null | number>(null);
  // eslint-disable-next-line
  const [rap, setRap] = useState<null | number>(null);
  const [workoutData, setWorkoutData] = useState<any>();
  const [workoutEquip, setWorkoutEquip] = useState([]);
  const [workoutTrainings, setWorkoutTraining] = useState<any>([]);
  const params = useParams();
  const id: any = params?.id;
  const [currentVideo, setCurrentVideo] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [previousRest, setPreviousRest] = useState<number>(0);
  const [nextRestForRestPopup, setNextRestForRestPopup] = useState<number>(0);
  const [isAudioPlay, setIsAudioPlay] = useState<boolean>(false);
  // eslint-disable-next-line
  const [isRestAudioPlay, setIsRestAudioPlay] = useState<boolean>(false);

  const navigate = useNavigate();

  const vidArray: any = [];
  const instruction = workoutData?.data?.instruction;

  // eslint-disable-next-line
  workoutData?.data?.workout_sets.map((ele: any) => {
    // eslint-disable-next-line
    ele?.workout_durations?.map((item: any) => {
      // eslint-disable-next-line
      item.video = ele.exercise.video.media;
      // eslint-disable-next-line
      item.videoName = ele.exercise.exercise_name;
      // eslint-disable-next-line
      item.description = ele.exercise.description;
      vidArray.push(item);
    });
  });

  const getWorkoutDataById = async () => {
    setIsLoading(true);
    try {
      const res: any = await GET_BY_ID_WORKOUT(id);
      setWorkoutData(res);
    } catch (err: any) {
      message.error(err.message || 'something wen wrong');
    }
    setIsLoading(false);
  };

  // Next Workout From Media Popup And Rest Popup
  const nextWorkout = () => {
    setVideoPopupVisible(false);
    setRestPopupVisible(true);
    setCounter(vidArray[currentVideo]?.rest_time);
    setCurrentVideo(currentVideo);
  };

  const nextWorkoutFromRest = () => {
    setRestPopupVisible(false);
    setVideoPopupVisible(true);
    if (vidArray.length - 1 > currentVideo) {
      setCurrentVideo(currentVideo + 1);
    } else {
      setRestPopupVisible(false);
      setVideoPopupVisible(false);
      setCompletePopupVisible(true);
      setCurrentVideo(0);
    }
  };

  // Previous Workout From Media Popup And Rest Popup
  const prevWorkout = () => {
    setVideoPopupVisible(false);
    setRestPopupVisible(true);
    if (vidArray.length > currentVideo) {
      setCurrentVideo(currentVideo - 1);
      setCounter(vidArray[currentVideo - 1]?.rest_time);
    } else {
      setRestPopupVisible(false);
      setVideoPopupVisible(false);
      setCompletePopupVisible(true);
      setCurrentVideo(0);
    }
  };

  const prevWorkoutFromRest = () => {
    setRestPopupVisible(false);
    setVideoPopupVisible(true);
    setCurrentVideo(currentVideo);
  };

  // Set Previous and next Title for Video Player
  const nextTitle = () => {
    if (vidArray.length >= 1 && videoPopupVisible) {
      return 'Rest';
    }
    if (vidArray.length >= 1 && restPopupVisible) {
      return vidArray[currentVideo + 1]?.videoName || 'Rest';
    }
    return 'Rest';
  };

  const prevTitle = () => {
    if (vidArray.length >= 1 && restPopupVisible) {
      return vidArray[currentVideo]?.videoName;
    }
    if (vidArray.length >= 1 && videoPopupVisible) {
      return 'Rest';
    }
    return 'Rest';
  };

  // It returns next video link in rest popup video player
  const nextVideoLink = () => {
    if (vidArray.length - 1 > currentVideo) {
      return vidArray[currentVideo + 1].video;
    }
    return null;
  };

  const workoutEquipment = () => {
    // console.log(workoutData);
    const equip = workoutData?.data?.workout_equipments
      .map(
        (workoutEquipmentData: Equipment) =>
          workoutEquipmentData?.equipment?.name,
      )
      .filter(
        (workoutEquipmentDataString: string) => workoutEquipmentDataString,
      );
    const finalEquip = equip?.join(', ') || null;
    setWorkoutEquip(finalEquip);
  };

  const workoutTrainingZones = () => {
    const training = workoutData?.data?.workout_training_zones
      .map(
        (workoutTrainingZoneData: TrainingZone) =>
          workoutTrainingZoneData?.training_zone?.name,
      )
      .filter(
        (workoutTrainingZoneDataString: string) =>
          workoutTrainingZoneDataString,
      );
    const trainingList = training?.join(',') || null;
    setWorkoutTraining(trainingList);
  };

  const clearVideo = () => {
    setCurrentVideo(0);
  };

  // This useEffect fire when counter 0 and restPopupVisible is true || We can say that Rest popup timer
  useEffect(() => {
    if (counter === 0 && restPopupVisible) {
      setRestPopupVisible(false);
      setVideoPopupVisible(true);
      if (vidArray.length - 1 > currentVideo) {
        setCurrentVideo(currentVideo + 1);
      } else {
        setRestPopupVisible(false);
        setVideoPopupVisible(false);
        setCurrentVideo(0);
        setCompletePopupVisible(true);
      }
    }
  }, [counter, currentVideo, vidArray, restPopupVisible]);

  useEffect(() => {
    if (progress === 100 && isAudioPlay) {
      setIsAudioPlay(false);
      setVideoPopupVisible(false);
      if (vidArray.length >= 1) {
        setRestPopupVisible(true);
        setProgress(0);
      }
    }
  }, [isAudioPlay, progress]);

  useEffect(() => {
    getWorkoutDataById();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    workoutTrainingZones();
    workoutEquipment();
    // eslint-disable-next-line
  }, [workoutData]);

  // when currentVideo value change and currentVideo and videoPopupVisible is true;
  useEffect(() => {
    if (videoPopupVisible) {
      setDuration(Number(vidArray[currentVideo]?.rest_time));
      setCounter(Number(vidArray[currentVideo]?.rest_time));

      // here I have set next rest time for rest timer
      if (vidArray.length - 1 > currentVideo) {
        const nextTempDuration: any = vidArray[currentVideo + 1]?.duration || 0;
        if (nextTempDuration) {
          const timeParts = nextTempDuration.split(':'); // Split the string into an array of parts
          const seconds =
            +timeParts[0] * 60 * 60 + +timeParts[1] * 60 + +timeParts[2]; // Convert the parts to seconds
          setNextRestForRestPopup(Number(seconds));
        }
      } else {
        setNextRestForRestPopup(0);
      }

      // here I have set previous rest time for player and rest time both
      if (currentVideo >= 0 && restPopupVisible) {
        const prevTime = vidArray[currentVideo - 1]?.duration || 0;
        if (prevTime) {
          const timeParts = prevTime.split(':'); // Split the string into an array of parts
          const seconds =
            +timeParts[0] * 60 * 60 + +timeParts[1] * 60 + +timeParts[2]; // Convert the parts to seconds
          setPreviousRest(Number(seconds));
        }
      } else if (currentVideo >= 0 && videoPopupVisible) {
        setPreviousRest(Number(vidArray[currentVideo - 1]?.rest_time) || 0);
      } else {
        setPreviousRest(0);
      }

      if (vidArray[currentVideo]?.type === 'rap') {
        setRap(vidArray[currentVideo]?.rap_count);
      } else {
        const tempDuration = vidArray[currentVideo]?.duration || 0;

        if (tempDuration) {
          const a: any = tempDuration?.split(':');
          const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
          setTime(seconds);
        }
      }
    }
  }, [currentVideo, videoPopupVisible]);

  // when rest pop is true then we need to setTime to null value
  useEffect(() => {
    if (restPopupVisible) {
      setTime(null);
    }
  }, [restPopupVisible]);

  return (
    <div className={styles.info}>
      <div className={styles.header_wrapper}>
        <Button onClick={() => navigate(-1)} className={styles.header_icon}>
          <ArrowLeftIcon />
        </Button>
        <h2 className={styles.header_title}>Program</h2>
      </div>
      <div className={styles.info_header_wrapper}>
        <img
          crossOrigin="anonymous"
          src={workoutData?.data?.thumbnail?.media || defaultImg}
          className={styles.info_header_img}
          alt=""
        />
        <div className={styles.info_content}>
          <h2 className={styles.info_content_title}>
            {workoutData?.data?.workout_name}
          </h2>
          <Divider />
          <p className={styles.info_content_icon_text}>
            <Clock />
            {`${workoutData?.data?.duration} Weeks`}
          </p>
          <p className={styles.info_content_icon_text}>
            <Fire />
            {workoutTrainings}
          </p>
          <p className={styles.info_content_icon_text}>
            <LevelIcon />
            {workoutData?.data?.fitness_level}
          </p>
          <p className={styles.info_content_icon_text}>
            <DumbbellsIcon />
            {workoutEquip}
          </p>
          <div className={styles.btn_wrapper}>
            <Button
              type="primary"
              size="large"
              onClick={() => setVideoPopupVisible(true)}
              style={{ color: '#000' }}
              className={styles.btn_start}
            >
              Start Workout
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.info_footer}>
        <div>
          <h3 className={styles.info_footer_title}>Description</h3>
          <p>{workoutData?.data?.description}</p>
        </div>
        <Divider />

        <div>
          <h3 className={styles.info_footer_title}>Instructions</h3>
          <p>{workoutData?.data?.instruction}</p>
        </div>
      </div>
      {restPopupVisible && (
        <RestPopup
          setIsRestAudioPlay={setIsRestAudioPlay}
          visible={restPopupVisible}
          setVisible={setRestPopupVisible}
          counter={counter}
          setCounter={setCounter}
          nextWorkoutFromRest={nextWorkoutFromRest}
          clearVideo={clearVideo}
          previousFromRest={prevWorkoutFromRest}
          previousRest={previousRest}
          nextTitle={nextTitle}
          previousTitle={prevTitle}
          nextRestForRestPopup={nextRestForRestPopup}
          nextVideoLink={nextVideoLink}
        />
      )}
      {videoPopupVisible && (
        <MediaPopupProgram
          instruction={instruction}
          // rap={rap}
          time={time}
          setTime={setTime}
          setIsAudioPlay={setIsAudioPlay}
          visible={videoPopupVisible}
          setVisible={setVideoPopupVisible}
          data={vidArray}
          prog={setProgress}
          next={nextWorkout}
          current={currentVideo}
          counter={counter}
          duration={duration}
          clearVideo={clearVideo}
          pre={prevWorkout}
          previousRest={previousRest}
          nextTitle={nextTitle}
          previousTitle={prevTitle}
        />
      )}
      {completePopupVisible && (
        <WorkOutCompletePopup
          visible={completePopupVisible}
          setVisible={setCompletePopupVisible}
        />
      )}

      {isLoading && <Spinner />}
    </div>
  );
};

export default InfoProgram;
