// Builtin
import React, { useEffect, useState } from 'react';

// External
import { Button, Divider, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

// internal
import styles from '../info/info.module.css';
import Fire from '../../assets/icons/Fire';
import Clock from '../../assets/icons/Clock';
import LevelIcon from '../../assets/icons/LevelIcon';
import DumbbellsIcon from '../../assets/icons/DumbbellsIcon';
import defaultImg from '../../assets/imgs/defaultimg.png';
import WeeklyWorkoutModule from '../../components/WeeklyWorkoutModule/WeeklyWorkoutModule';
import { SECTION } from '../../services/section/Section';
import Spinner from '../../utils/Spinner/Spinner';
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon';

const Program = () => {
  const [itemData, setItemData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const [equipments, setEquipments] = useState([]);
  const [trainingZone, setTrainingZone] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const id: any = params?.id;
  const getSectionDataById = async () => {
    setIsLoading(true);
    try {
      const res: any = await SECTION.GET_BY_ID_SECTION(id);
      setItemData(res);
    } catch (err: any) {
      message.error(err.message || 'something wen wrong');
    }
    setIsLoading(false);
  };

  const equipment = () => {
    const equip = itemData?.data?.program?.program_equipments
      .map((data: any) => data?.equipment?.name)
      // eslint-disable-next-line
      .filter((equipment: any) => equipment);
    const finalEquip = equip?.join(', ') || null;
    setEquipments(finalEquip);
  };

  const trainingZones = () => {
    const training = itemData?.data?.program?.program_training_zones
      .map((info: any) => info?.training_zone?.name)
      // eslint-disable-next-line
      .filter((training: any) => training);
    const trainingList = training?.join(',') || null;
    setTrainingZone(trainingList);
  };

  useEffect(() => {
    if (id) {
      getSectionDataById();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    equipment();
    trainingZones();
    // eslint-disable-next-line
  }, [itemData]);

  return (
    <>
      <div>
        <div className={styles.info}>
          <div className={styles.header_wrapper}>
            <Button onClick={() => navigate(-1)} className={styles.header_icon}>
              <ArrowLeftIcon />
            </Button>
            <h2 className={styles.header_title}>Program</h2>
          </div>
          <div className={styles.info_header_wrapper}>
            <img
              crossOrigin="anonymous"
              src={itemData?.data?.program?.thumbnail?.media || defaultImg}
              className={styles.info_header_img}
              alt=""
            />
            <div className={styles.info_content}>
              <h2 className={styles.info_content_title}>
                {itemData?.data?.program?.program_name}
              </h2>
              <Divider />
              <p className={styles.info_content_icon_text}>
                <Clock />
                {itemData?.data?.program?.program_duration} Weeks
              </p>
              <p className={styles.info_content_icon_text}>
                <Fire />
                {trainingZone}
              </p>
              <p className={styles.info_content_icon_text}>
                <LevelIcon />
                {itemData?.data?.program?.fitness_level}
              </p>
              <p className={styles.info_content_icon_text}>
                <DumbbellsIcon />
                {equipments}
              </p>
            </div>
          </div>
          <div className={styles.info_footer}>
            <div>
              <h3 className={styles.info_footer_title}>Description</h3>
              <p>{itemData?.data?.program?.description}</p>
            </div>
            <Divider />
          </div>
        </div>

        <WeeklyWorkoutModule data={itemData?.data?.program} />
      </div>
      {isLoading && <Spinner />}
    </>
  );
};

export default Program;
