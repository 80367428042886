// Built in
import React from 'react';

// External
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

// Internal
import styles from './headerWithBackBtn.module.css';
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon';

const HeaderWithBackBtn = ({
  title,
  path,
}: {
  title: string | null;
  path: string | number;
}) => {
  const navigate: any = useNavigate();

  return (
    <div className={styles.header_wrapper}>
      <Button onClick={() => navigate(path)} className={styles.header_icon}>
        <ArrowLeftIcon />
      </Button>
      <p className={styles.header_title}>{title}</p>
    </div>
  );
};

export default HeaderWithBackBtn;
