export const PrevArrow = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.80883 10.0339C3.86392 10.6954 3.39147 11.0261 3.22498 11.4381C3.0793 11.7986 3.0793 12.2016 3.22498 12.5621C3.39147 12.9741 3.86392 13.3048 4.80883 13.9662L9.48249 17.2378C10.2973 17.8081 10.7046 18.0933 11.0437 18.0785C11.339 18.0655 11.6135 17.9227 11.7934 17.6882C12 17.4189 12 16.9216 12 15.927V8.07313C12 7.07858 12 6.58131 11.7934 6.31201C11.6135 6.07751 11.339 5.93461 11.0437 5.92169C10.7046 5.90685 10.2973 6.19202 9.48249 6.76236L4.80883 10.0339Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8088 10.0339C12.8639 10.6954 12.3915 11.0261 12.225 11.4381C12.0793 11.7986 12.0793 12.2016 12.225 12.5621C12.3915 12.9741 12.8639 13.3048 13.8088 13.9662L18.4825 17.2378C19.2973 17.8081 19.7046 18.0933 20.0437 18.0785C20.339 18.0655 20.6135 17.9227 20.7934 17.6882C21 17.4189 21 16.9216 21 15.927V8.07313C21 7.07858 21 6.58131 20.7934 6.31201C20.6135 6.07751 20.339 5.93461 20.0437 5.92169C19.7046 5.90685 19.2973 6.19202 18.4825 6.76236L13.8088 10.0339Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
