// External
import { Route, Routes } from 'react-router-dom';

// internal
import Page404 from '../pages/Page404/Page404';
import PrivateRoute from './PrivateRoutes';
import PublicRoute from './PublicRoutes';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import SeeAll from '../pages/SeeAll/SeeAll';
import Info from '../pages/info/Info';
import Program from '../pages/Program/Program';
import InfoProgram from '../pages/Program/InfoProgram';

const AppRoutes = () => {
  return (
    <Routes>
      {/* public routes */}
      <Route element={<PublicRoute />}>
        <Route path="/login" element={<Login />} />
      </Route>

      {/* private routes */}
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Home />} />
        <Route path="/seeAll/:id" element={<SeeAll />} />
        <Route path="/info/:id" element={<Info />} />
        <Route path="/program/:id" element={<Program />} />
        <Route path="/program/:id/info/:id" element={<InfoProgram />} />
      </Route>

      {/* page not found */}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default AppRoutes;
