// Built in
import React, { useEffect, useState } from 'react';

//  External
import { message } from 'antd';

// Internal
import { useAuth } from '../../context/AuthContext';
import { SECTION } from '../../services/section/Section';
import Feed from '../../components/Feed/Feed';
import Spinner from '../../utils/Spinner/Spinner';

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sectionData, setSectionData] = useState<any>();
  const condition = !isLoading && sectionData?.length >= 1;
  const { search } = useAuth();

  const fetchSection = async (searchValue: string | null = null) => {
    setIsLoading(true);
    try {
      const res = await SECTION.GET(searchValue);
      if (res?.status === 1 || res?.status === '1') {
        setSectionData(res?.data);
      } else {
        setSectionData([]);
      }
    } catch (err: any) {
      message.error(err?.message || 'Something went wrong!');
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchSection(search);
  }, [search]);

  return (
    <div>
      {condition ? (
        sectionData?.map((item: any) => <Feed data={item} key={item?.id} />)
      ) : (
        <h1
          className={`${
            isLoading
              ? 'd-none'
              : 'w-100 h-100 flex align-center justify-center'
          }`}
        >
          No Data Found
        </h1>
      )}

      {isLoading && <Spinner />}
    </div>
  );
};

export default Home;
