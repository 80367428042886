// Builtin
import React, { useEffect, useState } from 'react';

// External
import { Button, Divider, message } from 'antd';
import { useParams } from 'react-router-dom';

// Internal
import styles from './info.module.css';
import { SECTION } from '../../services/section/Section';
import HeaderWithBackBtn from '../../components/HeaderWithBackBtn/HeaderWithBackBtn';
import Fire from '../../assets/icons/Fire';
import Clock from '../../assets/icons/Clock';
import LevelIcon from '../../assets/icons/LevelIcon';
import DumbbellsIcon from '../../assets/icons/DumbbellsIcon';
import { Equipment, TrainingZone } from './types';
import WorkOutCompletePopup from '../../components/WorkoutCompletePopup/WorkoutCompletePopup';
import MediaPopup from '../../components/MediaPopup/MediaPopup';
import RestPopup from '../../components/RestPopup/Rest';
import defaultImg from '../../assets/imgs/defaultimg.png';
import Spinner from '../../utils/Spinner/Spinner';

const Info = () => {
  const [restPopupVisible, setRestPopupVisible] = useState(false);
  const [videoPopupVisible, setVideoPopupVisible] = useState(false);
  const [completePopupVisible, setCompletePopupVisible] = useState(false);
  const [time, setTime] = useState<null | number>(null);
  // eslint-disable-next-line
  const [rap, setRap] = useState<null | number>(null);
  const [currentVideo, setCurrentVideo] = useState<number>(0);
  const [counter, setCounter] = useState<any>(0);
  const [progress, setProgress] = useState(0);
  const [previousRest, setPreviousRest] = useState<number>(0);
  const [nextRestForRestPopup, setNextRestForRestPopup] = useState<number>(0);
  const [isAudioPlay, setIsAudioPlay] = useState<boolean>(false);
  const [duration, setDuration] = useState<any>();
  // States For the equipments and zones
  const [equipments, setEquipments] = useState([]);
  const [workoutEquipments, setWorkoutEquipments] = useState([]);
  const [trainingZone, setTrainingZone] = useState([]);
  const [workoutTrainingZone, setWorkoutTrainingZone] = useState([]);

  const [itemData, setItemData] = useState<any>();
  // eslint-disable-next-line
  const [isRestAudioPlay, setIsRestAudioPlay] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const vidArray: any = [];

  const instruction = itemData?.data?.workout?.instruction;
  const workoutTime = itemData?.data?.workout?.duration;

  // eslint-disable-next-line
  itemData?.data?.workout?.workout_sets.map((ele: any) => {
    // eslint-disable-next-line
    ele?.workout_durations?.map((item: any) => {
      // eslint-disable-next-line
      item.video = ele.exercise.video.media;
      // eslint-disable-next-line
      item.videoName = ele.exercise.exercise_name;
      // eslint-disable-next-line
      item.description = ele.exercise.description;
      vidArray.push(item);
    });
  });

  const params = useParams();
  const id: any = params?.id;

  // Clear the video list when workout complete or closed the popup
  const clearVideo = () => {
    setCurrentVideo(0);
  };

  // Section Api Call
  const getSectionDataById = async () => {
    setIsLoading(true);
    try {
      const res: any = await SECTION.GET_BY_ID_SECTION(id);
      setItemData(res);
    } catch (err: any) {
      message.error(err.message || 'something wen wrong');
    }
    setIsLoading(false);
  };

  // Set Previous and next Title for Video Player
  const nextTitle = () => {
    if (vidArray.length >= 1 && videoPopupVisible) {
      return 'Rest';
    }
    if (vidArray.length >= 1 && restPopupVisible) {
      return vidArray[currentVideo + 1]?.videoName || 'Rest';
    }
    return 'Rest';
  };

  const prevTitle = () => {
    if (vidArray.length >= 1 && restPopupVisible) {
      return vidArray[currentVideo]?.videoName;
    }
    if (vidArray.length >= 1 && videoPopupVisible) {
      return 'Rest';
    }
    return 'Rest';
  };

  // Next Workout From Media Popup And Rest Popup
  const nextWorkout = () => {
    setVideoPopupVisible(false);
    setRestPopupVisible(true);
    setCounter(vidArray[currentVideo]?.rest_time);
    setCurrentVideo(currentVideo);
  };

  const nextWorkoutFromRest = () => {
    setRestPopupVisible(false);
    setVideoPopupVisible(true);
    if (vidArray.length - 1 > currentVideo) {
      setCurrentVideo(currentVideo + 1);
    } else {
      setRestPopupVisible(false);
      setVideoPopupVisible(false);
      setCompletePopupVisible(true);
      setCurrentVideo(0);
    }
  };

  // Previous Workout From Media Popup And Rest Popup
  const prevWorkout = () => {
    setVideoPopupVisible(false);
    setRestPopupVisible(true);
    if (vidArray.length > currentVideo) {
      setCurrentVideo(currentVideo - 1);
      setCounter(vidArray[currentVideo - 1]?.rest_time);
    } else {
      setRestPopupVisible(false);
      setVideoPopupVisible(false);
      setCompletePopupVisible(true);
      setCurrentVideo(0);
    }
  };

  const prevWorkoutFromRest = () => {
    setRestPopupVisible(false);
    setVideoPopupVisible(true);
    setCurrentVideo(currentVideo);
  };

  // It returns next video link in rest popup video player
  const nextVideoLink = () => {
    if (vidArray.length - 1 > currentVideo) {
      return vidArray[currentVideo + 1].video;
    }
    return null;
  };

  // Equipment and Training zone Data
  const equipment = () => {
    const equip = itemData?.data?.exercise?.exercise_equipments
      .map((equipmentData: Equipment) => equipmentData?.equipment?.name)
      .filter((equipmentDataString: string) => equipmentDataString);
    const finalEquip = equip?.join(', ') || null;
    setEquipments(finalEquip);
  };

  const workoutEquipment = () => {
    const equip = itemData?.data?.workout?.workout_equipments
      .map(
        (workoutEquipmentData: Equipment) =>
          workoutEquipmentData?.equipment?.name,
      )
      .filter(
        (workoutEquipmentDataString: string) => workoutEquipmentDataString,
      );
    const finalEquip = equip?.join(', ') || null;
    setWorkoutEquipments(finalEquip);
  };

  const trainingZones = () => {
    const training = itemData?.data?.exercise?.exercise_training_zones
      .map(
        (trainingZoneData: TrainingZone) =>
          trainingZoneData?.training_zone?.name,
      )
      .filter((trainingZoneDataString: string) => trainingZoneDataString);
    const trainingList = training?.join(',') || null;
    setTrainingZone(trainingList);
  };

  const workoutTrainingZones = () => {
    const training = itemData?.data?.workout?.workout_training_zones
      .map(
        (workoutTrainingZoneData: TrainingZone) =>
          workoutTrainingZoneData?.training_zone?.name,
      )
      .filter(
        (workoutTrainingZoneDataString: string) =>
          workoutTrainingZoneDataString,
      );
    const trainingList = training?.join(',') || null;
    setWorkoutTrainingZone(trainingList);
  };

  // Use effect for Calling section api
  useEffect(() => {
    if (id) {
      getSectionDataById();
    }
  }, [id]);

  // use Effect for calling equipments and training zones
  useEffect(() => {
    equipment();
    workoutEquipment();
    trainingZones();
    workoutTrainingZones();
  }, [itemData]);

  // When Video timer equals 0 it stop the bell noise and open rest popup
  useEffect(() => {
    if (progress === 100 && isAudioPlay) {
      setIsAudioPlay(false);
      setVideoPopupVisible(false);
      if (vidArray.length >= 1) {
        setRestPopupVisible(true);
        setProgress(0);
      }
    }
  }, [isAudioPlay, progress]);

  // This useEffect fire when counter 0 and restPopupVisible is true || We can say that Rest popup timer
  useEffect(() => {
    if (counter === 0 && restPopupVisible) {
      setRestPopupVisible(false);
      setVideoPopupVisible(true);
      if (vidArray.length - 1 > currentVideo) {
        setCurrentVideo(currentVideo + 1);
      } else {
        setRestPopupVisible(false);
        setVideoPopupVisible(false);
        setCurrentVideo(0);
        setCompletePopupVisible(true);
      }
    }
  }, [counter, currentVideo, vidArray, restPopupVisible]);

  // when currentVideo value change and currentVideo and videoPopupVisible is true;
  useEffect(() => {
    if (videoPopupVisible) {
      setDuration(Number(vidArray[currentVideo]?.rest_time));
      setCounter(Number(vidArray[currentVideo]?.rest_time));

      // here I have set next rest time for rest timer
      if (vidArray.length - 1 > currentVideo) {
        const nextTempDuration: any = vidArray[currentVideo + 1]?.duration || 0;
        if (nextTempDuration) {
          const timeParts = nextTempDuration.split(':'); // Split the string into an array of parts
          const seconds =
            +timeParts[0] * 60 * 60 + +timeParts[1] * 60 + +timeParts[2]; // Convert the parts to seconds
          setNextRestForRestPopup(Number(seconds));
        }
      } else {
        setNextRestForRestPopup(0);
      }

      // here I have set previous rest time for player and rest time both
      if (currentVideo >= 0 && restPopupVisible) {
        const prevTime = vidArray[currentVideo - 1]?.duration || 0;
        if (prevTime) {
          const timeParts = prevTime.split(':'); // Split the string into an array of parts
          const seconds =
            +timeParts[0] * 60 * 60 + +timeParts[1] * 60 + +timeParts[2]; // Convert the parts to seconds
          setPreviousRest(Number(seconds));
        }
      } else if (currentVideo >= 0 && videoPopupVisible) {
        setPreviousRest(Number(vidArray[currentVideo - 1]?.rest_time) || 0);
      } else {
        setPreviousRest(0);
      }

      if (vidArray[currentVideo]?.type === 'rap') {
        setRap(vidArray[currentVideo]?.rap_count);
      } else {
        const tempDuration = vidArray[currentVideo]?.duration || 0;

        if (tempDuration) {
          const a: any = tempDuration?.split(':');
          const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
          setTime(seconds);
        }
      }
    }
  }, [currentVideo, videoPopupVisible]);

  // when rest pop is true then we need to setTime to null value for complete popup
  useEffect(() => {
    if (restPopupVisible) {
      setTime(null);
    }
  }, [restPopupVisible]);

  return (
    <div className={styles.info}>
      <HeaderWithBackBtn title={itemData?.data?.section?.name} path={-1} />
      <div className={styles.info_header_wrapper}>
        <img
          crossOrigin="anonymous"
          src={
            itemData?.data?.exercise?.thumbnail?.media ||
            itemData?.data?.workout?.thumbnail?.media ||
            defaultImg
          }
          className={styles.info_header_img}
          alt=""
        />
        <div className={styles.info_content}>
          <h2 className={styles.info_content_title}>
            {itemData?.data?.exercise?.exercise_name ||
              itemData?.data?.workout?.workout_name}
          </h2>
          <Divider />
          <p className={styles.info_content_icon_text}>
            <Clock />
            <div className="flex gap-1">
              {itemData?.data?.exercise?.video_duration || workoutTime || '-'}
              <p>Hours</p>
            </div>
          </p>
          <p className={styles.info_content_icon_text}>
            <Fire />
            {trainingZone || workoutTrainingZone}
          </p>
          <p className={styles.info_content_icon_text}>
            <LevelIcon />
            {itemData?.data?.exercise?.exercise_category ||
              itemData?.data?.workout?.fitness_level}
          </p>
          <p className={styles.info_content_icon_text}>
            <DumbbellsIcon />
            {equipments || workoutEquipments}
          </p>
          <div className={styles.btn_wrapper}>
            <Button
              type="primary"
              size="large"
              onClick={() => setVideoPopupVisible(true)}
              style={{ color: '#000' }}
              className={styles.btn_start}
            >
              {itemData?.data?.item_type === 'exercise'
                ? 'Start Exercise'
                : 'Start Workout'}
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.info_footer}>
        <div>
          <h3 className={styles.info_footer_title}>Description</h3>
          <p>
            {itemData?.data?.exercise?.description ||
              itemData?.data?.workout?.description}
          </p>
        </div>
        <Divider />
        {itemData?.data?.workout ? (
          <div>
            <h3 className={styles.info_footer_title}>Instructions</h3>
            <p>{itemData?.data?.workout?.instruction}</p>
          </div>
        ) : null}
      </div>

      {restPopupVisible && (
        <RestPopup
          visible={restPopupVisible}
          setVisible={setRestPopupVisible}
          counter={counter}
          setCounter={setCounter}
          nextWorkoutFromRest={nextWorkoutFromRest}
          clearVideo={clearVideo}
          previousFromRest={prevWorkoutFromRest}
          previousRest={previousRest}
          nextTitle={nextTitle}
          previousTitle={prevTitle}
          setIsRestAudioPlay={setIsRestAudioPlay}
          nextRestForRestPopup={nextRestForRestPopup}
          nextVideoLink={nextVideoLink}
          data={vidArray.length > 0 ? vidArray : itemData.data}
          current={currentVideo}
        />
      )}
      {videoPopupVisible && (
        <MediaPopup
          instruction={instruction}
          // rap={rap}
          time={time}
          setTime={setTime}
          setIsAudioPlay={setIsAudioPlay}
          visible={videoPopupVisible}
          setVisible={setVideoPopupVisible}
          data={vidArray.length > 0 ? vidArray : itemData.data}
          prog={setProgress}
          next={nextWorkout}
          current={currentVideo}
          counter={counter}
          duration={duration}
          clearVideo={clearVideo}
          pre={prevWorkout}
          previousRest={previousRest}
          nextTitle={nextTitle}
          previousTitle={prevTitle}
        />
      )}

      {completePopupVisible && (
        <WorkOutCompletePopup
          visible={completePopupVisible}
          setVisible={setCompletePopupVisible}
        />
      )}

      {isLoading && <Spinner />}
    </div>
  );
};

export default Info;
