// Built In
import React from 'react';

// External
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

// Internal
import { CloseIconRest } from '../../assets/icons/CloseIconRest';
import styles from './workoutcompletepopup.module.css';
import RestMan from '../../assets/icons/complete-man.svg';

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const WorkOutCompletePopup = ({ visible, setVisible }: Props) => {
  const navigate = useNavigate();
  return (
    <Modal
      title={null}
      centered
      open={visible}
      onOk={() => setVisible(false)}
      onCancel={() => {
        setVisible(false);
      }}
      maskClosable={false}
      className={styles.modal}
      footer={null}
      bodyStyle={{ background: '#CC9C00', height: '700px' }}
      closeIcon={
        <CloseIconRest
          style={{
            fontSize: '24px',
          }}
        />
      }
    >
      {/* main content */}
      <div className={styles.main_div}>
        <img src={RestMan} alt="rest-man" className={styles.rest_man} />
        <h2 className={styles.bottom_heading}>
          Workout Completed, See you in the Next Workout.
        </h2>
        <Button className={styles.button_div} onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </div>
    </Modal>
  );
};

export default WorkOutCompletePopup;
