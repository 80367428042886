import React, { useEffect, useRef } from 'react';

// Internal
import { Modal, Tooltip } from 'antd';

// External
import Bell from '../../assets/audio/bell.mp3';
import styles from './mediapopup.module.css';
import useMediaPlayer from '../../hooks/useMediaPlayer';
import { CloseIcon } from '../../assets/icons/MediaPopup/CloseIcon';
import { QuestionMarkIcon } from '../../assets/icons/MediaPopup/QuestionMarkIcon';
import { PrevArrow } from '../../assets/icons/MediaPopup/PrevArrow';
import { InfoIcon } from '../../assets/icons/MediaPopup/InfoIcon';
import { PlayIcon } from '../../assets/icons/MediaPopup/PlayIcon';
import { PauseIcon } from '../../assets/icons/MediaPopup/PauseIcon';
import { UnmuteIcon } from '../../assets/icons/MediaPopup/UnmuteIcon';
import { MuteIcon } from '../../assets/icons/MediaPopup/MuteIcon';
import { NextArrow } from '../../assets/icons/MediaPopup/NextArrow';

interface Props {
  setTime: any;
  time: any;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  data: any;
  prog: (visible: number) => void;
  next: () => void;
  current: number;
  duration: number;
  counter: number;
  clearVideo: () => void;
  pre: () => void;
  previousRest: number;
  setIsAudioPlay: React.Dispatch<React.SetStateAction<boolean>>;
  nextTitle: () => string;
  previousTitle: () => string;
  instruction: string;
}

const MediaPopup = ({
  instruction,
  visible,
  setVisible,
  data,
  prog,
  next,
  current,
  duration,
  counter,
  clearVideo,
  pre,
  previousRest,
  setIsAudioPlay,
  nextTitle,
  previousTitle,
  time,
  setTime,
}: Props) => {
  const videoElement: any = useRef(null);

  const {
    playerState,
    setPlayerState,
    togglePlay,
    handleOnTimeUpdate,
    toggleMute,
  } = useMediaPlayer(videoElement);

  useEffect(() => {
    if (time === null) {
      prog(playerState?.progress);
    }
    if (time === 0) {
      prog(100);
    }
  }, [playerState?.progress, prog, time]);

  // console.log({ time });

  // eslint-disable-next-line
  useEffect(() => {
    if (time > 0 && videoElement?.current?.readyState > 2) {
      // if (!counter) return;
      const intervalId = setInterval(() => {
        if (!playerState.isPlaying) return;
        setTime(time - 1);
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [time, playerState.isPlaying, setTime, videoElement?.current?.readyState]);

  // // when the video is finish playing then it will be play bell audio after this duration rest pop will appear
  useEffect(() => {
    const audio = new Audio(Bell);
    if (time === 0) {
      setIsAudioPlay(true);
      audio.play();
    } else {
      audio.pause();
    }

    if (time === null) {
      audio.addEventListener('timeupdate', () => {
        const percentage = (audio.currentTime / audio.duration) * 100;
        if (percentage === 100) {
          setIsAudioPlay(true);
        }
      });
      if (playerState?.progress === 100) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  }, [playerState?.progress, setIsAudioPlay, setTime, time]);

  useEffect(() => {
    if (counter === 0) {
      setPlayerState({
        ...playerState,
        isPlaying: true,
      });
    }
  }, [counter, playerState, setPlayerState, togglePlay]);

  // Player Minutes
  const playerMinutes = String(Math.floor(time / 60)).padStart(2, '0');
  const playerSeconds = String(time % 60).padStart(2, '0');

  // Next Exercise Minutes
  const restMinutes = String(Math.floor(duration / 60)).padStart(2, '0');
  const restSeconds = String(duration % 60).padStart(2, '0');

  // Previous Exercise Minutes
  const preMinutes = String(Math.floor(previousRest / 60)).padStart(2, '0');
  const pretSeconds = String(previousRest % 60).padStart(2, '0');

  return (
    <div>
      <Modal
        title={null}
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => {
          setVisible(false);
          clearVideo();
        }}
        maskClosable={false}
        className={styles.modal}
        footer={null}
        bodyStyle={{ padding: 0, background: '#000' }}
        closeIcon={<CloseIcon style={{ fontSize: '24px' }} />}
      >
        <div className={styles.container}>
          <div className={styles.video_wrapper}>
            <div className={styles.div_main}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  <p className={styles.head_1}>
                    {data.length >= 1
                      ? data[current]?.videoName
                      : data?.exercise?.exercise_name}
                  </p>
                  {data.length >= 1 && (
                    <div>
                      <Tooltip
                        title={instruction}
                        color="#FBC90B"
                        placement="top"
                      >
                        <button type="button" className={styles.mute_btn}>
                          <QuestionMarkIcon />
                        </button>
                      </Tooltip>
                    </div>
                  )}
                </div>
                {data.length >= 1 && (
                  <p className={styles.timer_head}>
                    {data[current]?.duration !== null &&
                      `${playerMinutes}:${playerSeconds}`}
                    {data[current]?.rap_count !== null &&
                      `REP: ${data[current]?.rap_count}`}
                  </p>
                )}
              </div>
            </div>
            {/* eslint-disable jsx-a11y/media-has-caption  */}
            <video
              playsInline
              // eslint-disable-next-line
              webkit-playsInline
              crossOrigin="anonymous"
              src={
                data.length > 0
                  ? data[current]?.video
                  : data?.exercise?.video?.media
              }
              ref={videoElement}
              onTimeUpdate={handleOnTimeUpdate}
              className={styles.video}
              loop
              autoPlay
            />
            <div className={styles.controls}>
              <div
                className={styles.playpausebuttons}
                style={{
                  justifyContent: data?.item_type && 'center',
                }}
              >
                {data.length >= 1 && (
                  <div style={{ width: '35%', overflow: 'hidden' }}>
                    {/* eslint-disable-next-line */}
                    <button
                      disabled={current === 0}
                      className={styles.prevtopbtn}
                      style={{
                        cursor: current === 0 ? 'not-allowed' : 'pointer',
                      }}
                      onClick={() => pre()}
                    >
                      <PrevArrow />
                      <span className={styles.text_prev_next}>
                        Previous Exercise
                      </span>
                      <span className={styles.smalltext}>Previous</span>
                    </button>
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'end',
                        marginTop: '5px',
                      }}
                    >
                      <span
                        className={styles.next_prev_title}
                        style={{ textAlign: 'end', width: '100%' }}
                      >
                        {previousTitle()}
                      </span>
                      <p className={styles.next_prev_timer}>
                        {`${preMinutes}:${pretSeconds}`}
                      </p>
                    </div>
                  </div>
                )}
                <div>
                  <Tooltip title={data[current]?.description} color="#FBC90B">
                    <button type="button" className={styles.mute_btn}>
                      <InfoIcon />
                    </button>
                  </Tooltip>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={togglePlay}
                    className={styles.mainbtnplaypause}
                  >
                    {!playerState.isPlaying ? <PlayIcon /> : <PauseIcon />}
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className={styles.mute_btn}
                    onClick={toggleMute}
                  >
                    {!playerState.isMuted ? <UnmuteIcon /> : <MuteIcon />}
                  </button>
                </div>
                {data.length >= 1 && (
                  <div style={{ width: '35%', overflow: 'hidden' }}>
                    {/* eslint-disable-next-line */}
                    <div className={styles.righttopbtn} onClick={() => next()}>
                      <span className={styles.text_prev_next}>
                        Next Exercise
                      </span>
                      <span className={styles.smalltext}>Next</span>
                      <NextArrow />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'start',
                        marginTop: '5px',
                      }}
                    >
                      <p className={styles.next_prev_timer}>
                        {`${restMinutes}:${restSeconds}`}
                      </p>
                      <span className={styles.next_prev_title}>
                        {nextTitle()}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MediaPopup;
