// Built in
import React, { useState } from 'react';

// External
import { useNavigate } from 'react-router-dom';
import { Button, Input, Layout, Popover, message } from 'antd';

// internal
import NavLogo from '../assets/imgs/navLogo.png';
import styles from './layout.module.css';
import { useAuth } from '../context/AuthContext';
import { LOGOUT } from '../services/auth/Logout';
import { UserIcon } from '../assets/icons/UserIcon';
import { SearchIcon } from '../assets/icons/SearchIcon';
import { Search } from '../assets/icons/Search';
import LogoutIcon from '../assets/icons/LogoutIcon';

const { Content } = Layout;
interface IProps {
  children: React.ReactNode;
}
const AppLayout = ({ children }: IProps) => {
  const [tempSearch, setTempSearch] = useState<string | null>(null);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const { setToken, setUserData, setSearch } = useAuth();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.value) {
      setSearch(null);
      setTempSearch(null);
    } else {
      setTempSearch(e?.target?.value);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e?.key === 'Enter') {
      setSearch(tempSearch);
    }
  };

  const logout = async () => {
    try {
      const res = await LOGOUT();

      if (res?.status === 1 || res?.status === '1') {
        localStorage.clear();
        setToken(null);
        setUserData(null);
        navigate('/login');
        message.success('Successfully logout');
      }
    } catch (error) {
      message.error('Something Went Wrong');
    }
  };

  const logoutPopupContent = (
    <Button
      onClick={logout}
      type="link"
      className="font-weight-600 button-logout flex align-center g-4"
    >
      <LogoutIcon /> <span>Logout</span>
    </Button>
  );

  return (
    <div>
      <div className={styles.header_comp}>
        <div className={styles.logo_wrapper}>
          <img src={NavLogo} alt="SBZ" className={styles.main_logo} />
          {visible ? (
            <Input
              // style={{ display: 'none' }}
              // style={{ display: 'none' }}
              placeholder="Search..."
              prefix={<SearchIcon />}
              style={{ borderRadius: '8px' }}
              // className={styles.search_btn_input}
              allowClear
              onChange={handleOnChange}
              onKeyPress={handleKeyPress}
            />
          ) : (
            <img src={NavLogo} alt="SBZ" className={styles?.search_logo} />
          )}

          <Input
            // style={{ display: 'none' }}
            placeholder="Search..."
            prefix={<SearchIcon />}
            className={styles.search}
            allowClear
            onChange={handleOnChange}
            onKeyPress={handleKeyPress}
          />
        </div>
        <div
          role="presentation"
          className={styles.search_icon}
          onClick={() => setVisible(!visible)}
          onKeyDown={() => setVisible(!visible)}
        >
          <Search style={{ cursor: 'pointer' }} className={styles.search_svg} />
        </div>
        <Popover
          content={logoutPopupContent}
          placement="bottomRight"
          title={null}
        >
          <UserIcon className={styles.user_icon} />
        </Popover>
      </div>
      <Content>
        <div className={styles.content}>{children}</div>
      </Content>
    </div>
  );
};

export default AppLayout;
