// Built In
import React, { useEffect } from 'react';

// External
import { Modal } from 'antd';

// Internal
import RestMan from '../../assets/icons/rest-man.svg';
import styles from './rest.module.css';
import Bell from '../../assets/audio/bell.mp3';
import CloseBlackIcon from '../../assets/icons/RestPopup/CloseBlackIcon';
import PrevBlackIcon from '../../assets/icons/RestPopup/PrevBlackIcon';
import NextBlackIcon from '../../assets/icons/RestPopup/NextBlackIcon';
import InfoBlackIcon from '../../assets/icons/RestPopup/InfoBlackIcon';

interface Props {
  current: number;
  visible: boolean;
  data: any;
  setIsRestAudioPlay: (visible: boolean) => void;
  setVisible: (visible: boolean) => void;
  nextRestForRestPopup: number;
  counter: number;
  setCounter: (value: number) => void;
  clearVideo: () => void;
  previousFromRest: () => void;
  nextWorkoutFromRest: () => void;
  previousRest: number;
  nextTitle: () => string;
  previousTitle: () => string;
  nextVideoLink: () => string;
}

const Rest = ({
  visible,
  setVisible,
  counter,
  setCounter,
  clearVideo,
  previousFromRest,
  previousRest,
  nextWorkoutFromRest,
  nextTitle,
  previousTitle,
  nextRestForRestPopup,
  nextVideoLink,
  setIsRestAudioPlay,
  // eslint-disable-next-line
  current,
  // eslint-disable-next-line
  data,
}: Props) => {
  // used for changing the timer
  useEffect(() => {
    if (counter > 0) {
      if (!counter) return;
      const intervalId = setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
      // eslint-disable-next-line consistent-return
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [counter, setCounter]);

  // used for ringing the bell sound when counter is 0
  useEffect(() => {
    const audio = new Audio(Bell);
    if (counter === 0) {
      setIsRestAudioPlay(true);
      audio.play();
    } else {
      audio.pause();
    }

    if (counter === null) {
      audio.addEventListener('timeupdate', () => {
        const percentage = (audio.currentTime / audio.duration) * 100;
        if (percentage === 100) {
          setIsRestAudioPlay(true);
        }
      });
      if (counter === 0) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  }, [counter, setIsRestAudioPlay]);

  const restMinutes = String(Math.floor(nextRestForRestPopup / 60)).padStart(
    2,
    '0',
  );
  const restSeconds = String(nextRestForRestPopup % 60).padStart(2, '0');

  const preMinutes = String(Math.floor(previousRest / 60)).padStart(2, '0');
  const preSeconds = String(previousRest % 60).padStart(2, '0');

  const counterMinutes = String(Math.floor(counter / 60)).padStart(2, '0');
  const counterSeconds = String(counter % 60).padStart(2, '0');

  // const getVideoUrl = (workout: any) => {
  //   if (workout[current].workout_durations.length - 1 > currentWorkoutSets) {
  //     return workout[current]?.exercise?.video?.media;
  //   }
  //   if (workout.length - 1 > current) {
  //     return workout[current + 1]?.exercise?.video?.media;
  //   }
  //   return null;
  // };
  return (
    <Modal
      title={null}
      centered
      open={visible}
      onOk={() => setVisible(false)}
      onCancel={() => {
        setVisible(false);
        clearVideo();
      }}
      maskClosable={false}
      className={styles.modal}
      footer={null}
      bodyStyle={{ background: '#CC9C00', height: '700px' }}
      closeIcon={
        <CloseBlackIcon
          style={{
            fontSize: '24px',
          }}
        />
      }
    >
      <div>
        <div className={styles.main_div_top}>
          <h3 className="font-weight-600 m-0">
            REST
            <InfoBlackIcon style={{ marginLeft: '10px' }} />
          </h3>
          <h2 className="font-weight-600 " style={{ fontSize: '30px' }}>
            {`${counterMinutes}:${counterSeconds}`}
          </h2>
        </div>

        {/* main content */}
        <div
          className={
            nextVideoLink() ? styles.maincontent : styles.main_without_video
          }
        >
          <div className={nextVideoLink() && styles.contentleft}>
            <h2 className={styles.left_heading}>It’s Rest time</h2>
            <img src={RestMan} alt="rest-man" className={styles.rest_man} />
          </div>
          {nextVideoLink() && (
            <div className={styles.rightBox}>
              <h2 className={styles.heading}> Next Exercise</h2>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video
                playsInline
                // eslint-disable-next-line
                webkit-playsInline
                crossOrigin="anonymous"
                // src={getVideoUrl(data.workout.workout_sets)}
                src={nextVideoLink()}
                autoPlay
              />
            </div>
          )}
        </div>

        {/* footer */}
        <div className={styles.modal_footer}>
          <div className={styles.leftFooter}>
            <button
              type="button"
              className={styles.next_exercise_button}
              onClick={() => previousFromRest()}
            >
              <span className={styles.exercise_text}>
                <PrevBlackIcon style={{ marginRight: '10px' }} />
                Previous Exercise
              </span>
              <h4 className={styles.smalltext}>
                <PrevBlackIcon style={{ marginRight: '10px' }} />
                Previous
              </h4>
            </button>
            <p>
              <span className={styles.exercise_textbottom}>
                {previousTitle()}
              </span>
              <span className={styles.next_prev_timer}>
                {`${preMinutes}:${preSeconds}`}
              </span>
            </p>
          </div>

          <div className={styles.rightFooter}>
            <button
              type="button"
              className={styles.next_exercise_button}
              onClick={() => nextWorkoutFromRest()}
            >
              <span className={styles.exercise_text}>
                Next Exercise
                <NextBlackIcon style={{ marginLeft: '10px' }} />
              </span>
              <h4 className={styles.smalltext}>
                Next
                <NextBlackIcon style={{ marginLeft: '10px' }} />
              </h4>
            </button>
            <p>
              <span className={styles.next_prev_timer}>
                {`${restMinutes}:${restSeconds}`}
              </span>
              <span className={styles.exercise_textbottom}>{nextTitle()}</span>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Rest;
