import React from 'react';

const CaretRight = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 7.93137V16.0686C9 16.6744 9 16.9774 9.1198 17.1176C9.22374 17.2393 9.37967 17.3039 9.53923 17.2914C9.72312 17.2769 9.93731 17.0627 10.3657 16.6343L14.4343 12.5657C14.6323 12.3677 14.7313 12.2687 14.7684 12.1545C14.8011 12.0541 14.8011 11.9459 14.7684 11.8455C14.7313 11.7313 14.6323 11.6323 14.4343 11.4343L10.3657 7.36569L10.3657 7.36568C9.93731 6.93731 9.72312 6.72312 9.53923 6.70865C9.37967 6.69609 9.22374 6.76068 9.1198 6.88238C9 7.02265 9 7.32555 9 7.93137Z"
      stroke="#C69E03"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CaretRight;
