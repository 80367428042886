export const NextArrow = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.3069 13.9663C20.2518 13.3049 20.7243 12.9742 20.8907 12.5622C21.0364 12.2017 21.0364 11.7987 20.8907 11.4382C20.7243 11.0262 20.2518 10.6954 19.3069 10.034L14.6332 6.76244C13.8185 6.1921 13.4111 5.90694 13.072 5.92177C12.7767 5.9347 12.5022 6.07759 12.3223 6.31209C12.1157 6.58139 12.1157 7.07866 12.1157 8.07321V15.9271C12.1157 16.9217 12.1157 17.4189 12.3223 17.6882C12.5022 17.9227 12.7767 18.0656 13.072 18.0786C13.4111 18.0934 13.8185 17.8082 14.6332 17.2379L19.3069 13.9663Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3069 13.9663C11.2518 13.3049 11.7243 12.9742 11.8907 12.5622C12.0364 12.2017 12.0364 11.7987 11.8907 11.4382C11.7243 11.0262 11.2518 10.6954 10.3069 10.034L5.63323 6.76244C4.81846 6.1921 4.41108 5.90694 4.07197 5.92177C3.77668 5.9347 3.50224 6.07759 3.32231 6.31209C3.11569 6.58139 3.11569 7.07866 3.11569 8.07321V15.9271C3.11569 16.9217 3.11569 17.4189 3.32231 17.6882C3.50224 17.9227 3.77668 18.0656 4.07197 18.0786C4.41108 18.0934 4.81846 17.8082 5.63323 17.2379L10.3069 13.9663Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
