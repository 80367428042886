import React from 'react';

const Clock = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0001 12.1667V9.66667M10.0001 5.5V3M7.50008 3H12.5001M14.1667 8L15.8334 6.33333M15.8334 12.1667C15.8334 15.3883 13.2217 18 10.0001 18C6.77842 18 4.16675 15.3883 4.16675 12.1667C4.16675 8.94501 6.77842 6.33333 10.0001 6.33333C13.2217 6.33333 15.8334 8.94501 15.8334 12.1667Z"
      stroke="#01B8FE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Clock;
