// Built In
import React, { useState } from 'react';

// External
import { Button, Form, Input, message } from 'antd';

// Internal
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { LOGIN } from '../../services/auth/Login';
import styles from './login.module.css';
import { HomeIcon } from '../../assets/icons/HomeIcon';
import Spinner from '../../utils/Spinner/Spinner';

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { setToken, setUserData } = useAuth();

  const handleLogin = async (value: { email: string; password: string }) => {
    setIsLoading(true);
    try {
      const login = {
        email: value.email.toLowerCase().trim(),
        password: value.password,
        strategy: 'local',
      };
      const res = await LOGIN(login);

      if (res.status === '1' || res.status === 1) {
        const token = res.data.accessToken;
        const userData: any = {
          username: res?.data?.user?.username,
        };
        localStorage.setItem('token', token);
        localStorage.setItem('user-data', JSON.stringify(userData));
        setToken(token);
        setUserData(userData);
        message.success('Login Successfully !');
        navigate('/');
      }
    } catch (err: any) {
      message.error('Invalid Email and Password');
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className={styles.login_page_wrapper}>
        <div className={styles.login_page_img_wrapper}>
          <HomeIcon style={{ height: '100%', width: '96%', margin: 'auto' }} />
        </div>
        <div className={styles.login_form_wrapper}>
          <Form onFinish={handleLogin} layout="vertical">
            <h1 className={styles.login_title}>Welcome Back !</h1>
            <p className={styles.login_description}>
              Go inside the best Fitness collection!
            </p>
            <div>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Email is required !' },
                  {
                    message: 'Please input Valid Email!',
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.resolve();
                      }
                      // eslint-disable-next-line
                      if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject();
                    },
                  },
                ]}
              >
                <Input
                  // eslint-disable-next-line
                  onInput={(e: any) =>
                    (e.target.value = e.target.value.toLowerCase())
                  }
                  placeholder="Enter Email"
                  // prefix={<UserOutlined />}
                  className="input-control border-radius-5 w-100"
                  size="large"
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Password is required !' }]}
              >
                <Input.Password
                  type="password"
                  // prefix={<LockOutlined />}
                  placeholder="Enter Password"
                  className="input-control border-radius-5 w-100"
                  size="large"
                  allowClear
                />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={`ant-btn-lg w-100 ${styles.login_btn}`}
              >
                Sign In
              </Button>
            </div>
          </Form>
        </div>
      </div>
      {isLoading && (
        <>
          <div className="blur1" />
          <Spinner />
        </>
      )}
    </>
  );
};

export default Login;
