export const MuteIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3333 12.6667L28 19.3333M28 12.6667L21.3333 19.3333M6.13333 12H7.33493C8.0695 12 8.43679 12 8.77714 11.9084C9.0787 11.8273 9.36367 11.6938 9.61906 11.5141C9.90729 11.3112 10.1424 11.0291 10.6127 10.4648L14.1139 6.26331C14.6948 5.56622 14.9853 5.21767 15.239 5.1814C15.4587 5.14999 15.6796 5.22998 15.8283 5.39477C16 5.58506 16 6.03877 16 6.94618V25.0538C16 25.9612 16 26.4149 15.8283 26.6052C15.6796 26.77 15.4587 26.85 15.239 26.8186C14.9853 26.7823 14.6948 26.4337 14.1139 25.7367L10.6127 21.5352C10.1424 20.9709 9.90729 20.6887 9.61906 20.4859C9.36367 20.3062 9.0787 20.1727 8.77714 20.0916C8.43679 20 8.0695 20 7.33493 20H6.13333C5.3866 20 5.01323 20 4.72801 19.8547C4.47713 19.7268 4.27316 19.5229 4.14532 19.272C4 18.9868 4 18.6134 4 17.8667V14.1333C4 13.3866 4 13.0132 4.14532 12.728C4.27316 12.4771 4.47713 12.2731 4.72801 12.1453C5.01323 12 5.3866 12 6.13333 12Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
