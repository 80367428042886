// Built In
import React, { useEffect, useState } from 'react';

// External
import { Link } from 'react-router-dom';
import { Col } from 'antd';

// Internal
import styles from './video.module.css';
import Clock from '../../assets/icons/Clock';
import Fire from '../../assets/icons/Fire';
import defaultimg from '../../assets/imgs/defaultimg.png';

interface TrainingZone {
  id: string;
  training_zone: {
    id: string;
    name: string;
  };
}

interface WorkoutTrainingZone {
  id: string;
  training_zone: {
    id: string;
    name: string;
  };
}

const VideoComp = ({ data }: any) => {
  const [trainingZone, setTrainingZone] = useState([]);
  const [workoutTrainingZone, setWorkoutTrainingZone] = useState([]);
  const [programTrainingZone, setProgramTrainingZone] = useState([]);

  const trainingZones = () => {
    const training = data?.exercise?.exercise_training_zones
      .map(
        (trainingZonesName: TrainingZone) =>
          trainingZonesName?.training_zone?.name,
      )
      .filter((trainingZonesString: string) => trainingZonesString);
    const trainingList = training?.join(',') || null;
    setTrainingZone(trainingList);
  };

  const workoutTrainingZones = () => {
    const training = data?.workout?.workout_training_zones
      .map(
        (workoutTrainingZoneName: WorkoutTrainingZone) =>
          workoutTrainingZoneName?.training_zone?.name,
      )
      .filter((workoutTrainingZoneString: string) => workoutTrainingZoneString);
    const trainingList = training?.join(',') || null;
    setWorkoutTrainingZone(trainingList);
  };

  const programTrainingZones = () => {
    const training = data?.program?.program_training_zones
      .map((dataProg: TrainingZone) => dataProg?.training_zone?.name)
      .filter((dataProgString: string) => dataProgString);
    const trainingList = training?.join(',') || null;
    setProgramTrainingZone(trainingList);
  };

  useEffect(() => {
    trainingZones();
    workoutTrainingZones();
    programTrainingZones();
    // eslint-disable-next-line
  }, []);

  return (
    <Col
      xxl={6}
      xl={6}
      lg={6}
      md={12}
      sm={12}
      span={24}
      style={{ marginBottom: '24px' }}
    >
      <div className={styles.see_all_item}>
        <Link
          to={
            data?.item_type === 'program'
              ? `/program/${data?.id}`
              : `/info/${data?.id}`
          }
          className={styles.video}
        >
          <div className={styles.video_wrapper}>
            <img
              className={styles.video_img}
              crossOrigin="anonymous"
              src={
                data?.exercise?.thumbnail?.media ||
                data?.workout?.thumbnail?.media ||
                data?.program?.thumbnail?.media ||
                defaultimg
              }
              alt=""
            />
            <p className={styles.video_title}>
              {data?.exercise?.exercise_name ||
                data?.workout?.workout_name ||
                data?.program?.program_name}
            </p>
            <div className={styles.video_footer}>
              <p className={styles.video_footer_info_text}>
                <Clock />
                <span className={styles.video_footer_info_span}>
                  {data?.exercise?.video_duration &&
                    `${data?.exercise?.video_duration} Min`}
                  {data?.workout?.duration &&
                    `${data?.workout?.duration} Hours`}
                  {data?.program?.program_duration &&
                    `${data?.program.program_duration} Weeks`}
                </span>
              </p>
              <p className={styles.video_footer_info_text}>
                <Fire />
                <span className={styles.video_footer_info_span}>
                  {trainingZone || workoutTrainingZone || programTrainingZone}
                </span>
              </p>
            </div>
          </div>
        </Link>
      </div>
    </Col>
  );
};

export default VideoComp;
