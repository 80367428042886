export const PlayIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.0417 13.211C24.6632 14.123 25.4739 14.5791 25.744 15.1783C25.9794 15.7008 25.9794 16.2992 25.744 16.8217C25.4739 17.4209 24.6632 17.877 23.0417 18.789L11.4355 25.3175C9.86786 26.1993 9.08404 26.6402 8.44201 26.5676C7.88188 26.5043 7.37448 26.2076 7.04469 25.7504C6.66667 25.2264 6.66667 24.3271 6.66667 22.5285V9.47151C6.66667 7.67288 6.66667 6.77357 7.04469 6.24957C7.37448 5.79242 7.88188 5.49567 8.44201 5.43235C9.08404 5.35977 9.86786 5.80067 11.4355 6.68247L23.0417 13.211Z"
      fill="#0A0A09"
      stroke="#0A0A09"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
