import { message } from 'antd';
import Axios from 'axios';

export const SBZ = Axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

/* eslint-disable no-param-reassign */
SBZ.interceptors.request.use((config: any) => {
  const accessToken = localStorage.getItem('token');
  config.headers.Authorization = `Bearer ${accessToken}`;
  config.headers['Content-Type'] = 'application/json';
  return config;
});
/* eslint-enable no-param-reassign */

export const methods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};

const logout = () => {
  window.localStorage.clear();
  window.location.reload();
};

export const serviceMaker = async (
  url: string,
  method: string,
  params = null,
  data = {},
) => {
  let result: any;
  const APIInstance = SBZ;
  try {
    switch (method) {
      case 'GET': {
        result = await APIInstance.get(url, params as any);
        break;
      }
      case 'POST': {
        result = await APIInstance.post(url, data);
        break;
      }
      case 'PUT': {
        result = await APIInstance.put(url, data);
        break;
      }
      case 'DELETE': {
        result = await APIInstance.delete(url);
        break;
      }
      case 'PATCH': {
        result = await APIInstance.patch(url, data);
        break;
      }
      default: {
        // eslint-disable-next-line no-throw-literal
        break;
      }
    }
  } catch (err: any) {
    if (err?.response?.status === 401 && url !== '/authentication-app') {
      logout();
    } else if (url !== '/authentication-app') {
      message.error(err?.response?.data?.message || 'Something Went Wrong !');
    }
  }

  return result?.data;
};
