// Built In
import React from 'react';

// External
import { Button, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

// Internal
import styles from './feed.module.css';
import VideoComponent from '../VideoComponent/VideoComponent';
import { useAuth } from '../../context/AuthContext';
import CaretRight from '../../assets/icons/CaretRight';

const Feed = ({ data }: any) => {
  const navigate = useNavigate();
  const { search } = useAuth();

  const handleSeeAll = (id: string, name: string) => {
    navigate(`seeAll/${id}?sectionName=${name}`);
  };

  return (
    <div className={styles.feed_wrapper}>
      <div className={styles.feed_header}>
        <h2 className={styles.feed_header_title}>{data?.name}</h2>
        {search === null ? (
          <Button
            type="link"
            onClick={() => handleSeeAll(data?.id, data?.name)}
            className={styles.feed_header_see_all_btn}
          >
            See All <CaretRight />
          </Button>
        ) : null}
      </div>
      <div className={styles.feed_content}>
        <Row gutter={30}>
          {data?.section_items?.map((item: any) => {
            return <VideoComponent data={item} key={item?.id} />;
          })}
        </Row>
      </div>
    </div>
  );
};

export default Feed;
