import React from 'react';

import { useNavigate } from 'react-router-dom';

import style from './WeeklyWorkout.module.css';
import RightIcon from '../../assets/icons/ProgramRightIcon';
import pic from '../../assets/imgs/pic.png';

const WeeklyWorkoutModule = ({ data }: any) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className={style.maindiv}>
        {data?.program_weeks?.map((week: any) => {
          return (
            <div className={style.innerdiv} key={week?.id}>
              <p className={style.heading}>{week?.week_name}</p>
              {week?.program_week_workouts?.map((workouts: any) => {
                return (
                  <div className={style.divweeklyworkout} key={workouts?.id}>
                    {/* eslint-disable-next-line */}
                    <div
                      className={style.divlist}
                      onClick={() => navigate(`info/${workouts?.workout?.id}`)}
                    >
                      <div className="flex g-4">
                        {workouts?.workout?.thumbnail?.media ? (
                          <img
                            src={workouts?.workout?.thumbnail?.media}
                            alt="thumbnail"
                            crossOrigin="anonymous"
                            className={style.imgthumb}
                          />
                        ) : (
                          <img
                            src={pic}
                            alt="thumbnail"
                            crossOrigin="anonymous"
                            className={style.imgthumb}
                          />
                        )}
                        <div className="flex justify-between w-100 align-center">
                          <div className="flex flex-col g-2">
                            <p className={style.workoutnumber}>
                              Workout#{workouts?.position_no}
                            </p>
                            <p className={style.workoutname}>
                              {workouts?.workout?.workout_name}
                            </p>
                          </div>
                          <RightIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WeeklyWorkoutModule;
