// Builtin
import React, { useEffect, useState } from 'react';

// External
import { message, Row } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';

// Internal
import HeaderWithBackBtn from '../../components/HeaderWithBackBtn/HeaderWithBackBtn';
import VideoComponent from '../../components/VideoComponent/VideoComponent';
import { SECTION } from '../../services/section/Section';
import styles from './seeAll.module.css';
import Spinner from '../../utils/Spinner/Spinner';

const SeeAll = () => {
  const [sectionItem, setSectionItem] = useState<any>([]);
  const [searchParams] = useSearchParams();
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const { id }: any = useParams();

  const fetchAllSectionItem = async () => {
    setIsLoading(true);
    try {
      const limit = 20;
      const res = await SECTION.GET_SECTION_ITEM(id, 20, (page - 1) * limit);
      if (res?.status === 1 || res?.status === '1') {
        setSectionItem([...sectionItem, ...res.data]);
        setPage(page + 1);
      }
    } catch (error: any) {
      message.error(error?.message || 'Something went wrong!');
    }
    setIsLoading(false);
  };

  const handleScroll = () => {
    if (
      // Math.ceil(
      //   window.innerHeight + document.documentElement.scrollTop - 500,
      // ) !== document.documentElement.offsetHeight ||
      // isFetching
      window.scrollY + window.innerHeight !==
      document.documentElement.scrollHeight
    )
      return;
    setIsFetching(true);
  };

  const fetchMoreListItems = () => {
    fetchAllSectionItem();
    setIsFetching(false);
  };

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreListItems();
    // eslint-disable-next-line
  }, [isFetching]);

  useEffect(() => {
    fetchAllSectionItem();
    window.addEventListener('scroll', handleScroll);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={styles.list_wrapper}>
        <HeaderWithBackBtn title={searchParams.get('sectionName')} path="/" />
        <div>
          {/* className={styles.list_content_wrapper}> */}
          <Row gutter={30}>
            {sectionItem?.map((item: any) => {
              return <VideoComponent data={item} key={item.id} />;
            })}
          </Row>
        </div>
      </div>
      {isLoading && <Spinner />}
    </>
  );
};

export default SeeAll;
