import { serviceMaker, methods } from '../service';

const GET_SECTION = (search: string | null = null) => {
  const query: any = {
    params: {
      $limit: -1,
    },
  };
  if (search) {
    query.params.search = search;
  }

  return serviceMaker(`sections`, methods.GET, query);
};

const GET_SECTION_ITEM = (id: string | null, limit: number, skip: number) => {
  const query: any = {
    params: {
      $limit: limit,
      $skip: skip,
      sectionId: id,
    },
  };
  return serviceMaker(`section-items`, methods.GET, query);
};

const GET_BY_ID_SECTION = (id: string) =>
  serviceMaker(`section-items/${id}`, methods.GET);

export const SECTION = {
  GET: GET_SECTION,
  GET_SECTION_ITEM,
  GET_BY_ID_SECTION,
};
